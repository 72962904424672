import {Component, OnInit} from '@angular/core';
import {AbstractControl, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators,} from '@angular/forms';
import {CreateCampaignRequest} from '../../../models/requests/CreateCampaignRequest';
import {CampaignsService} from '../../../services/campaigns.service';
import {catchError, filter, of, take, tap} from 'rxjs';
import {Campaign} from '../../../models/Campaign';
import {UserService} from '../../../services/user.service';
import {CreateUserRequest} from '../../../models/requests/CreateUserRequest';
import {isLoggedIn, UserState} from '../../../store/user/user.store';
import {Store} from '@ngrx/store';
import {environment} from "../../../../../environments/environment";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {Nullable} from "../../../common/types";
import {MatProgressBar} from "@angular/material/progress-bar";
import {MatDialogTitle} from "@angular/material/dialog";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatOption, MatSelect} from "@angular/material/select";
import {NgForOf, NgIf} from "@angular/common";

@Component({
    selector: 'app-create-campaign',
    templateUrl: './create-campaign.component.html',
    styleUrls: ['./create-campaign.component.scss'],
    imports: [
        MatProgressBar,
        MatDialogTitle,
        ReactiveFormsModule,
        MatFormField,
        MatInput,
        MatError,
        MatLabel,
        TranslateModule,
        MatSelect,
        MatOption,
        NgIf,
        NgForOf
    ],
    standalone: true
})
export class CreateCampaignComponent implements OnInit {
    adminUrl = `//${environment.adminHost}`;
    userForm: UntypedFormGroup;
    campaignForm: UntypedFormGroup;

    _showCampaignDetails = false

    currencies = [
        'ILS',
        "USD",
        "EUR"
    ]

    constructor(
        private fb: UntypedFormBuilder,
        private userStateStore: Store<UserState>,
        private campaignService: CampaignsService,
        private snackBar: MatSnackBar,
        private translateService: TranslateService,
        private userService: UserService,
    ) {
    }

    ngOnInit(): void {
        this.campaignForm = this.fb.group({
            titleAr: ['', Validators.required],
            titleEn: ['', Validators.required],
            currency: ['', Validators.required],
        });

        this.userForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.minLength(10), Validators.maxLength(10)]],
            password: ['', [Validators.minLength(6), Validators.required]],
        });

        this.userStateStore.select(isLoggedIn).pipe(
            take(1),
            filter((loggedIn: boolean) => loggedIn),
            tap(() => this.showCampaignDetails())
        ).subscribe();
    }

    private showCampaignDetails() {
        this._showCampaignDetails = true
    }

    onUserSubmit(event: any) {
        event.preventDefault();
        if (this.userForm.invalid) return;
        this.userForm.disable()

        this.userService.register(this.transformUserFormToRequest()).pipe(
            tap(() => this.showCampaignDetails()),
            tap(() => this.userForm.enable()),
            tap(() => window.location.href = `${this.adminUrl}?lang=${this.translateService.currentLang}`),
            catchError(err => {
                this.snackBar.open(
                    this.translateService.instant("create-campaign.register.error"),
                    undefined,
                    {duration: 2000}
                )
                if (err.error && err.error.errors) {
                    Object.keys(err.error.errors).forEach((key: string) => {
                        this.setApiValidationError(this.userForm.get(key), err.error.errors[key])
                    })
                }
                this.userForm.enable()
                return of(null)
            })
        ).subscribe();
    }

    getErrorMessage(formControl: AbstractControl) {
        const formControlErrors = formControl?.errors;
        if (!formControlErrors || !Object.keys(formControlErrors)) {
            return '';
        }

        return `form-validation.${Object.keys(formControlErrors)[0]}`;
    }

    setApiValidationError(formControl: Nullable<AbstractControl>, errorKey: string) {
        if (!formControl) return

        setTimeout(() => {
            formControl.setErrors({[errorKey]: true})
            formControl.markAsDirty()
        }, 0);
    }

    onSubmit = (event: any) => {
        event.preventDefault();
        if (this.campaignForm.invalid) return;
        this.campaignForm.disable()

        this.campaignService
            .createCampaign(this.transformFormToCampaign())
            .pipe(
                take(1),
                tap(() => this.campaignForm.enable()),
                tap(
                    (createdCampaign: Campaign) =>
                        (window.location.href = `//${environment.adminHost}/campaigns/${createdCampaign.id}?lang=${this.translateService.currentLang}`)
                ),
                catchError(err => {
                    this.snackBar.open(this.translateService.instant("create-campaign.register.error"), undefined, {duration: 2000})
                    if (err.error && err.error.errors) {
                        Object.keys(err.error.errors).forEach((key: string) => {
                            this.campaignForm.get(key)?.setErrors({[err.error.errors[key]]: true})
                        })
                    }
                    this.campaignForm.enable()
                    return of(null)
                })
            )
            .subscribe();
    };

    transformFormToCampaign = (): CreateCampaignRequest => ({
        title_ar: this.campaignForm.value.titleAr,
        title_en: this.campaignForm.value.titleEn,
        currency: this.campaignForm.value.currency
    });

    transformUserFormToRequest = (): CreateUserRequest => ({
        name: this.userForm.value.name,
        email: this.userForm.value.email,
        phone: this.userForm.value.phone != "" ? this.userForm.value.phone : undefined,
        password: this.userForm.value.password,
        preferred_email_language: this.translateService.currentLang,
        flow: "campaign_creation"
    });

    gotToLogin() {
        const returnUrl = encodeURI(`//${window.location.host}?createCampaign=true`);
        window.location.href = `//${environment.adminHost}/login?returnUrl=${returnUrl}&lang=${this.translateService.currentLang}`;
    }

    isLoading() {
        return this.userForm.disabled || this.campaignForm.disabled
    }
}
