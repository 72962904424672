<mat-progress-bar *ngIf="isLoading()" mode="indeterminate" color="accent"></mat-progress-bar>

<h2 mat-dialog-title translate="createCampaign"></h2>

<div *ngIf="!_showCampaignDetails" class="personal-info">
    <h3 class="section-title" translate="create-campaign.personal-information"></h3>
    <form [formGroup]="userForm" (submit)="onUserSubmit($event)">
        <div class="row">
            <div class="col-sm-6 col-12">
                <mat-form-field appearance="fill">
                    <mat-label>{{'create-campaign.register.name' | translate}}</mat-label>
                    <input matInput name="name" autocomplete="name" formControlName="name">
                    <mat-error>{{getErrorMessage(userForm.get('name')!) | translate}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-6 col-12">
                <mat-form-field appearance="fill">
                    <mat-label>{{'create-campaign.register.phone' | translate}}</mat-label>
                    <input matInput formControlName="phone" name="phone" autocomplete="phone"/>
                    <mat-error>{{getErrorMessage(userForm.get('phone')!) | translate}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-12">
                <mat-form-field appearance="fill">
                    <mat-label>{{'create-campaign.register.email' | translate}}</mat-label>
                    <input matInput formControlName="email"
                           name="email" autocomplete="email"/>
                    <mat-error>
                        {{getErrorMessage(userForm.get('email')!) | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-6 col-12">
                <mat-form-field appearance="fill">
                    <mat-label>{{'create-campaign.register.password' | translate}}</mat-label>
                    <input matInput formControlName="password" type="password">
                    <mat-error>{{getErrorMessage(userForm.get('password')!) | translate}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div>
            <div class="col-12 text-center submit-button-div">
                <button class="form__submit" [disabled]="userForm.disabled || userForm.invalid"
                        type="submit">{{ 'create-campaign.register.create-account' | translate }}</button>
            </div>
            <div class="col-12 text-center">
                <span translate="create-campaign.register.haveAnAccount"></span>
                <b><u>
                    <a class="login-link" (click)="gotToLogin()" translate="create-campaign.register.login"></a>
                </u></b>
            </div>
        </div>
    </form>
</div>

<div *ngIf="_showCampaignDetails" class="campaign-info">
    <h3 class="section-title" translate="create-campaign.campaignDetailsTitle"></h3>
    <form [formGroup]="campaignForm"
          class="form account-form sign-in-form"
          (submit)="onSubmit($event)">
        <div class="row">
            <div class="col-12">
                <mat-form-field appearance="fill" class="full-width">
                    <mat-label>{{'create-campaign.titleAr' | translate}}</mat-label>
                    <input matInput formControlName="titleAr">
                    <mat-error>{{getErrorMessage(campaignForm.get('titleAr')!) | translate}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field appearance="fill" class="full-width">
                    <mat-label>{{'create-campaign.titleEn' | translate}}</mat-label>
                    <input matInput formControlName="titleEn">
                    <mat-error>{{getErrorMessage(campaignForm.get('titleEn')!) | translate}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field appearance="fill" class="full-width">
                    <mat-label>{{ 'create-campaign.currency' | translate }}</mat-label>
                    <mat-select formControlName="currency">
                        <mat-option *ngFor="let currency of currencies" [value]="currency">{{currency}}</mat-option>
                    </mat-select>
                    <mat-error>{{getErrorMessage(campaignForm.get('currency')!) | translate}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div>
            <div class="col-12 text-center">
                <button class="form__submit" type="submit" [disabled]="campaignForm.disabled || campaignForm.invalid">
                    {{ 'create-campaign.createCampaign' | translate }}
                </button>
            </div>
        </div>
    </form>
</div>
