import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map, Observable} from 'rxjs';
import {Campaign, MinimizedCampaign} from '../models/Campaign';
import {ApiResponse} from '../models/ApiResponse';
import {CreateCampaignRequest} from '../models/requests/CreateCampaignRequest';

@Injectable({
    providedIn: 'root',
})
export class CampaignsService {
    campaignsBaseUrl = `//${environment.domain}/${environment.apiPrefix}/campaigns`;

    constructor(private httpClient: HttpClient) {
    }

    listActiveCampaigns(): Observable<Array<MinimizedCampaign>> {
        return this.httpClient
            .get<ApiResponse<Array<MinimizedCampaign>>>(`${this.campaignsBaseUrl}`)
            .pipe(
                map(response => response.data!!.map(c => new MinimizedCampaign(c))),
                map(campaigns => campaigns.sort(this.sortMinimizedCampaign))
            );
    }

    private sortCampaign(a: Campaign, b: Campaign) {
        return b.getCreatedAt().getTime() - a.getCreatedAt().getTime()
    }

    private sortMinimizedCampaign(a: MinimizedCampaign, b: MinimizedCampaign) {
        return b.getCreatedAt().getTime() - a.getCreatedAt().getTime()
    }

    getCampaignById(id: string): Observable<Campaign> {
        return this.httpClient
            .get<ApiResponse<Campaign>>(`${this.campaignsBaseUrl}/${id}`)
            .pipe(map(response => new Campaign(response.data!!)));
    }

    createCampaign(campaign: CreateCampaignRequest): Observable<Campaign> {
        return this.httpClient
            .post<ApiResponse<Campaign>>(`${this.campaignsBaseUrl}`, campaign)
            .pipe(map(response => new Campaign(response.data!!)));
    }
}
